<template>
	<div>
		<div
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters()">
			<template v-slot:form>
				<div class="row">
					<div
						class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-striped">
				<thead>
					<tr>
						<th>{{ translate('order_type') }}</th>
						<th>{{ translate('quantity') }}</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in manualOrderTypes"
						:key="index">
						<td>{{ translate(item.order_type) }}</td>
						<td>{{ item.count }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading="loading"
			:loading-label="translate('loading')"
			:has-data="hasData"
			:no-data-label="translate('data_not_found')" />
	</div>
</template>

<script>
import Order from '@/util/Order';
import { OrderTypes, InventoryChanges, Grids } from '@/translations';
import FiltersParamsEnhanced from '@/mixins/FiltersParamsEnhanced';
import DataFilter from '@/components/DataFilter/index.vue';
import { DATE_RANGES as dateRanges, MDY_FORMAT as mdyFormat } from '@/settings/Dates';

export default {
	name: 'ManualOrderTypes',
	components: { DataFilter },
	messages: [OrderTypes, InventoryChanges, Grids],
	mixins: [FiltersParamsEnhanced],
	data() {
		return {
			orders: new Order(),
			dateRanges,
		};
	},
	computed: {
		manualOrderTypes() {
			try {
				return this.orders.data2.response.data.data ?? [];
			} catch (error) {
				return [];
			}
		},
		loading() {
			try {
				return this.orders.data2.loading;
			} catch (error) {
				return false;
			}
		},
		hasData() {
			return this.manualOrderTypes.length > 0;
		},
		rangeStart() {
			try {
				if (this.language) { // If the language changes, the date will be recalculated and displayed in the new language :)
					return this.$moment(this.orders.data2.response.data.period_dates.start_date.date).format(mdyFormat);
				}
			} catch (error) {
				// do nothing
			}
			return '';
		},
		rangeEnd() {
			try {
				if (this.language) { // If the language changes, the date will be recalculated and displayed in the new language :)
					return this.$moment(this.orders.data2.response.data.period_dates.end_date.date).format(mdyFormat);
				}
			} catch (error) {
				// do nothing
			}
			return '';
		},
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
	mounted() {
		this.getDataFiltered();
	},
	methods: {
		getDataFiltered() {
			this.orders.getManualOrderTypes(this.filters);
		},
	},
};
</script>

<style scoped>

</style>
